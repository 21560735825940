.countdown {
	margin-top: 40px;
	text-shadow: 1px 2px 6px rgba(0,0,0,0.4);

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-title {
		font-size: 2.3em;
		line-height: 1.6em;
	}

	&-number {
		display: flex;
		.days, .hours, .minutes, .seconds {
			margin: 0 4px;
			font-size: 2.3em;
		}
	}

	@include breakpoint('<=xsmall') {
		margin-top: 0;
		font-size: 0.7em;
	}

}
