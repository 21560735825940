// Burger menu button
$burger--thickness: 4px;
.burger {
  $width: 28px;

  display: inline-block;
  border: 0;
  position: absolute;
  top: 0;
  margin: 0.75em;
  right: 0;
  background: none;
  outline: 0;
  padding: 0;
  cursor: pointer;
  border-bottom: 2px solid currentColor;
  width: 24px;
  transition: border-bottom 1s ease-in-out;
  -webkit-transition: border-bottom 1s ease-in-out;

  // Fix for extra space in Firefox
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:before {
    content: "";
    display: block;
    border-bottom: 2px solid currentColor;
    width: 100%;
    margin-bottom: 4px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  &:after {
    content: "";
    display: block;
    border-bottom: 2px solid currentColor;
    width: 100%;
    margin-bottom: 4px;
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  @include breakpoint('>small') {
    display: none;
  }
}

.burger-check {
  display: none;
}

.burger-check:checked ~ .burger {
  border-bottom: $burger--thickness solid transparent;
  transition: border-bottom 0.8s ease-in-out;
  -webkit-transition: border-bottom 0.8s ease-in-out;

  &:before {
    transform: rotate(-405deg) translateY(4px) translateX(-3px);
    -webkit-transform: rotate(-405deg) translateY(4px) translateX(-3px);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }

  &:after {
    transform: rotate(405deg) translateY(-1px) translateX(0px);
    -webkit-transform: rotate(405deg) translateY(-1px) translateX(0px);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
  }
}

