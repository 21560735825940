///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Gallery */

#instafeed {
	& img {
		width: calc(100% / 4);
		height: auto;
		transition: transform .3s;

		&:hover {
			transform: scale(1.1);
		}

		@media (max-width: 768px) {
			width: calc(100% / 2);
		}
	}
}

	.gallery {
		@include vendor('display', 'flex');
		@include vendor('flex-wrap', 'wrap');
		width: 45em;
		max-width: 100%;
		margin: 0 auto _size(element-margin) auto;

		article {
			@include vendor('transition', (
				'transform 1s ease',
				'opacity 1s ease'
			));
			@include vendor('transform', 'translateX(0)');
			width: 50%;
			position: relative;
			opacity: 1.0;

			&.small {
				width: 25%;
			}

			.image {
				margin: 0;
				display: block;
			}

			& span.icon {
				color: #e2e2e2;
				position: absolute;
				margin: auto;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				left: 0;
				font-size: 1.7em;
				text-shadow: 1px 2px 6px rgba(0,0,0,0.4);
			}

			@for $i from 1 through 23 {
				&:nth-last-child(#{$i}n) {
					@include vendor('transition-delay', '#{(0.05s * $i)}');
				}

				&:nth-last-child(#{$i + 1}n) {
					@include vendor('transition-delay', '#{(0.05s * $i)}');
				}
			}
		}

		&.inactive {
			article {
				opacity: 0;

				&.from-left {
					@include vendor('transform', 'translateX(-14em)');
				}

				&.from-right {
					@include vendor('transform', 'translateX(14em)');
				}

				&.from-top {
					@include vendor('transform', 'translateY(-7em)');
				}

				&.from-bottom {
					@include vendor('transform', 'translateY(7em)');
				}
			}
		}

		@include breakpoint('<=xsmall') {
			@include vendor('flex-wrap', 'nowrap');
			@include vendor('flex-direction', 'column');

			&.small {
				@include vendor('flex-direction', 'row');
				@include vendor('flex-wrap', 'wrap');
			}

			article {
				width: 100%;
				&.small {
					width: 50%;
				}
			}
		}
	}
