.latest-info {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0;
	text-shadow: 1px 2px 6px rgba(0,0,0,0.4);

	& img {
		width: 112px;
		height: auto;
		box-shadow: 1px 2px 6px rgba(0,0,0,0.4);
	}

	& .text-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		& p {
			margin-left: 0.6em;
			margin-bottom: 0.5em;
			text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.6);
		}

		& a {
			padding: 12px 22px;
			border: 1px solid white;
			border-radius: 8px;
			text-decoration: none;
			position: relative;

			&:after {
				content: "";
				background-color: rgba(255, 255, 255, 0.3);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: all .3s;
			}

			&:hover:after {
				opacity: 0;
			}

			@include breakpoint('<=small') {
				padding: 5px 21px;
			}
		}
	}

	@include breakpoint('>xsmall') {
		font-size: 1.2em;
		& img {
			width: 156px;
		}
	}

	@include breakpoint('>small') {
		font-size: 1.2em;
	}

	@include breakpoint('>=large') {
		font-size: 1.6em;
		margin-top: 54px;
		& img {
			width: 216px;
		}
	}
}
