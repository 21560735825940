///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	.main {
		@include vendor('display', 'flex');
		position: relative;
		margin: 0;
		overflow-x: hidden;

		> .content {
			width: 45em;
			max-width: calc(100% - 4em);
			margin: 0 auto;

			> :last-child {
				margin-bottom: 0;
			}
		}

		&.fullscreen {
			min-height: 100%;
		}

		&.style1 {
			@include vendor('align-items', 'center');
			@include vendor('justify-content', 'center');
			text-align: center;
			padding: 3em 0 3em 0;

			h2 {
				font-size: 4.25em;
				line-height: 1em;
			}

			> .content {
				@include vendor('transition', 'opacity #{_duration(fade-in)} ease');
				@include vendor('transform', 'translateZ(0)');
				opacity: 1.0;
				margin: 0;
			}

			&.inactive > .content {
				opacity: 0;
			}
		}

		&.style2 {
			@include vendor('align-items', 'center');
			@include vendor('justify-content', 'center');
			padding: 3em 0 3em 0;
			overflow: hidden;

			> .content {
				@include vendor('transition', 'transform #{_duration(fade-in)} ease');
				@include vendor('transform', 'translateZ(0)');
				position: relative;
				width: 35%;
				margin: 0;
			}

			&.left {
				@include vendor('justify-content', 'flex-start');
			}

			&.right {
				@include vendor('justify-content', 'flex-end');
			}

			&.inactive {
				&.left > .content {
					@include vendor('transform', 'translateX(-100%)');
				}

				&.right > .content {
					@include vendor('transform', 'translateX(100%)');
				}
			}
		}

		&.style3 {
			text-align: center;
			padding: 6em 0 6em 0;

			.content > header {
				margin-bottom: 2em;
			}

			&.primary {
				background: _palette(bg);
			}

			&.secondary {
				background: _palette(bg-alt);
			}
		}

		&.dark {
			color: _palette(dark, fg);

			a {
				color: inherit;
			}

			.button.style2 {
				border-color: _palette(dark, fg);

				&:hover {
					background-color: transparentize(_palette(dark, border), 0.875);
				}

				&:active {
					background-color: transparentize(_palette(dark, border), 0.75);
				}

				&.down {
					background-image: url('../../assets/images/dark-arrow.svg');

					@include breakpoint('<=xsmall') {
						display: none;
					}
				}
			}
		}

		body.is-touch & {
			background-attachment: scroll !important;
		}

		@include breakpoint('<=xxlarge') {
			&.style2 {
				.content {
					width: 50%;
				}
			}
		}

		@include breakpoint('<=large') {
			&.style2 {
				.content {
					width: 60%;
				}
			}
		}

		@include breakpoint('<=medium') {
			&.style2 {
				.content {
					width: 70%;
				}
			}
		}

		@include breakpoint('<=small') {
			> .content {
				br {
					display: none;
				}
			}

			&.fullscreen {
				height: auto !important;
			}

			&.style1 {
				padding: 4em 15px 4em 15px;

				h2 {
					font-size: 3em;
				}
			}

			&.style2 {
				padding: 6em 15px 6em 15px;

				&:before,
				&:after {
					display: none !important;
				}

				.button.anchored {
					display: none;
				}

				.content {
					width: 100%;
					max-width: 100%;
					text-align: center;
					@include vendor('transform', 'none');
				}
			}

			&.style3 {
				text-align: center;
				padding: 3em 10px 3em 10px;
			}
		}

		@include breakpoint('<=xsmall') {
			> .content {
				max-width: calc(100% - 1.5em);
			}
		}
	}
