///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Table */

	table {
		width: 100%;

		&.default {
			width: 100%;

			tbody tr:nth-child(2n+2) {
				background: transparentize(_palette(border), 0.5);
			}

			td {
				padding: 0.5em 1em 0.5em 1em;
			}

			th {
				text-align: left;
				font-weight: 900;
				padding: 0.5em 1em 0.5em 1em;
			}

			thead {
				background: _palette(fg);
				color: _palette(bg);
			}

			tfoot {
				background: _palette(border);
			}
		}
	}