///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Poptrox */

	.poptrox-popup {
		@include vendor('box-sizing', 'content-box');
		background: #fff;
		padding-bottom: 3em;
		box-shadow: 0 0.1em 0.15em 0 rgba(0, 0, 0, 0.15);

		.loader {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -1em 0 0 -1em;
			width: 2em;
			height: 2em;
			display: block;
			font-size: 2em;

			&:before {
				@include vendor('animation', 'spinner-rotate 0.75s infinite linear !important');
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-transform: none !important;
				color: _palette(border);
				content: '\f1ce';
				cursor: default;
				display: block;
				height: 2em;
				left: 0;
				line-height: 2em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 2em;
			}
		}

		.caption {
			position: absolute;
			bottom: 0;
			left: 0;
			background: _palette(bg);
			width: 100%;
			height: 3em;
			line-height: 3.2em;
			text-align: center;
			cursor: default;
			z-index: 1;
			font-size: 1.1em;
		}

		.nav-next,
		.nav-previous {
			@include vendor('transition', 'opacity #{_duration(transition)} ease-in-out');
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			opacity: 0;
			cursor: pointer;
			background: rgba(0, 0, 0, 0.01);
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
		}

		.nav-next:before,
		.nav-previous:before {
			content: '';
			position: absolute;
			width: 96px;
			height: 64px;
			background: url('../../assets/images/poptrox-nav.svg');
			top: calc(50% - 1.5em);
			margin: -32px 0 0 0;
		}

		&:hover {
			.nav-next,
			.nav-previous {
				opacity: 0.5;
			}

			.nav-next:hover,
			.nav-previous:hover {
				opacity: 1.0;
			}
		}

		.nav-previous:before {
			@include vendor('transform', 'scaleX(-1)');
			-ms-filter: "FlipH";
			filter: FlipH;
		}

		.nav-next {
			right: 0;

			&:before {
				right: 0;
			}
		}

		.nav-previous {
			left: 0;

			&:before {
				left: 0;
			}
		}

		.closer {
			@include vendor('transition', 'opacity #{_duration(transition)} ease-in-out');
			position: absolute;
			top: 0;
			right: 0;
			width: 64px;
			height: 64px;
			text-indent: -9999px;
			z-index: 2;
			opacity: 0;
			-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

			&:before {
				content: '';
				display: block;
				position: absolute;
				right: 16px;
				top: 16px;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				box-shadow: inset 0 0 0 2px #fff;
				background: url('../../assets/images/poptrox-closer.svg') center center;
				color: _palette(bg) !important;
			}
		}

		&:hover {
			.closer {
				opacity: 0.5;

				&:hover {
					opacity: 1.0;
				}
			}
		}

		body.is-touch & {
			.nav-next,
			.nav-previous,
			.closer {
				opacity: 1.0 !important;
			}
		}

		@include breakpoint('<=small') {
			.nav-next:before,
			.nav-previous:before {
				width: 48px;
				height: 32px;
				background-size: contain;
				margin: -16px 0 0 0;
			}

			.closer:before {
				right: 12px;
				top: 12px;
				width: 20px;
				height: 20px;
				box-shadow: inset 0 0 0 1px #fff;
				background-size: contain;
				opacity: 0.65;
			}
		}
	}
