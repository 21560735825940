///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

	#footer {
		@include vendor('display', 'flex');
		@include vendor('align-items', 'center');
		@include vendor('justify-content', 'space-between');
		position: relative;
		margin: 0;
		line-height: 1em;
		padding: 1.5em;
		background: _palette(accent3, bg);
		color: _palette(accent3, fg);
		overflow: hidden;

		> * {
			margin-bottom: 0;
		}

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				color: _palette(accent3, fg-bold);
				text-decoration: underline;
			}
		}

		ul {
			&.menu {
				margin: 0;

				li {
					border-left-color: _palette(accent3, border);
					font-size: 0.9em;
				}
			}
		}

		@include breakpoint('<=medium') {
			@include vendor('flex-direction', 'column');
			@include vendor('justify-content', 'center');
			line-height: 1.5em;
			text-align: center;
			padding: 2em 1em 2em 1em;

			> * {
				margin: 0 0 1em 0;
			}
		}

		@include breakpoint('<=small') {
			ul {
				&.menu {
					li {
						border-left: none;
						display: block;
						line-height: inherit;
						margin: 0.25em 0 0 0;
						padding: 0.25em 0 0 0;

						&:first-child {
							margin-top: 0;
							padding-top: 0;
						}
					}
				}
			}
		}
	}
