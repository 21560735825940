///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

	form {
		margin: 0 0 _size(element-margin) 0;

		> :last-child {
			margin-bottom: 0;
		}

		> .fields  {
			$gutter: (_size(element-margin) * 0.75);

			@include vendor('display', 'flex');
			@include vendor('flex-wrap', 'wrap');
			width: calc(100% + #{$gutter * 2});
			margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

			> .field {
				@include vendor('flex-grow', '0');
				@include vendor('flex-shrink', '0');
				padding: $gutter 0 0 $gutter;
				width: calc(100% - #{$gutter * 1});

				&.half {
					width: calc(50% - #{$gutter * 0.5});
				}

				&.third {
					width: calc(#{100% / 3} - #{$gutter * (1 / 3)});
				}

				&.quarter {
					width: calc(25% - #{$gutter * 0.25});
				}
			}
		}

		@include breakpoint('<=xsmall') {
			> .fields {
				$gutter: (_size(element-margin) * 0.5);

				width: calc(100% + #{$gutter * 2});
				margin: ($gutter * -1) 0 _size(element-margin) ($gutter * -1);

				> .field {
					padding: $gutter 0 0 $gutter;
					width: calc(100% - #{$gutter * 1});

					&.half {
						width: calc(100% - #{$gutter * 1});
					}

					&.third {
						width: calc(100% - #{$gutter * 1});
					}

					&.quarter {
						width: calc(100% - #{$gutter * 1});
					}
				}
			}
		}
	}

	label {
		display: block;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="search"],
	input[type="url"],
	select,
	textarea {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'border-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out'
		));
		color: _palette(fg);
		display: block;
		width: 100%;
		padding: 0.65em 0.75em;
		background: none;
		border: solid 2px _palette(border);
		color: inherit;
		border-radius: 0.5em;
		outline: none;

		&:focus {
			border-color: _palette(accent2, bg);
		}
	}

	input[type="text"],
	input[type="password"],
	input[type="email"],
	input[type="tel"],
	input[type="search"],
	input[type="url"],
	select {
		line-height: 1.35em;
	}

	textarea {
		min-height: 8em;
	}

	::-moz-focus-inner {
		border: 0;
	}

	::-webkit-input-placeholder {
		opacity: 0.375;
	}

	:-moz-placeholder {
		opacity: 0.375;
	}

	::-moz-placeholder {
		opacity: 0.375;
	}

	:-ms-input-placeholder {
		opacity: 0.375;
	}