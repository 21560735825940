///
/// Big Picture by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	#header {
		position: fixed;
		z-index: 10000;
		left: 0;
		top: 0;
		width: 100%;
		background: transparentize(_palette(bg), 0.05);
		height: 3em;
		line-height: 3em;
		box-shadow: 0 0 0.15em 0 rgba(0,0,0,0.1);

		// Desktop social icons
		& ul.icons {
			position: absolute;
			right: 12px;
			margin-top: 4px;

			@include breakpoint('<=small') {
				display: none;
			}
		}

		// Mobile social icons
		+ ul.icons {
			background-color: rgba(255, 255, 255, 0.95);;
			width: 100%;
			display: flex;
			justify-content: center;
			position: unset;
			margin-top: 6px;

			& a {
				margin: 0 6px;
			}

			@include breakpoint('>small') {
				display: none;
			}
		}

		h1 {
			position: absolute;
			font-family: Ailerons, sans-serif;
			font-weight: 300;
			left: 1em;
			top: 0;
			height: 3em;
			line-height: 3em;
			cursor: default;

			a {
				font-size: 1.25em;
			}

			@include breakpoint('>small') {
				display: none;
			}
		}

		nav {
			padding-left: 12px;
			height: 3em;
			line-height: 3em;

			ul {
				margin: 0;

				li {
					display: inline-block;
					margin-left: 0.5em;
					font-size: 1.1em;

					a {
						display: block;
						color: inherit;
						text-decoration: none;
						height: 3em;
						line-height: 3em;
						padding: 0 0.5em 0 0.5em;
						outline: 0;
					}
				}
			}
		}

		@include breakpoint('<=small') {
			height: 2.5em;
			line-height: 2.5em;

			h1 {
				text-align: center;
				position: relative;
				left: 0;
				top: 0;
				height: 2.5em;
				line-height: 2.5em;

				a {
					font-size: 1em;
				}
			}

			// * * * Burger Navigation * * *
			$navigation__transition: max-height 0.5s ease-in-out;

			nav {
				overflow: hidden;
				max-height: 0;
				transition: $navigation__transition;
				background-color: #fff;
				position: absolute;
				top: 2.5em;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100vw;
				height: calc(100vh - 2.5em);
				padding: 0;

				& ul {
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					height: 100%;

					& li {
						margin-left: 0;
						font-size: 1.5em;
					}
				}
			}

			.burger-check:checked ~ nav {
				max-height: 200vh;
				transition: $navigation__transition;

			}
		}
	}

	body {
		padding-top: 3em;

		@include breakpoint('<=small') {
			padding-top: 2.5em;
		}
	}
