@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,900');

/*
	Big Picture by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

@include breakpoints(
  (
    xxlarge: (
      1681px,
      1920px,
    ),
    xlarge: (
      1281px,
      1680px,
    ),
    large: (
      1001px,
      1280px,
    ),
    medium: (
      737px,
      1000px,
    ),
    small: (
      481px,
      736px,
    ),
    xsmall: (
      null,
      480px,
    ),
  )
);

// Base.

@import 'base/reset';
@import 'base/page';
@import 'base/typography';

// Component.

@import 'components/box';
@import 'components/button';
@import 'components/form';
@import 'components/icon';
@import 'components/image';
@import 'components/list';
@import 'components/actions';
@import 'components/icons';
@import 'components/section';
@import 'components/table';
@import 'components/poptrox-popup';
@import 'components/gallery';
@import 'components/burger-menu';
@import 'components/countdown';
@import 'components/latest-info';

// Layout.

@import 'layout/header';
@import 'layout/main';
@import 'layout/footer';

#root,
.App {
  height: 100%;
}

/* Intro */

#intro {
  //background: url('images/overlay.png'), url('../../images/melantonic_smiling.jpg');
  background: url('../assets/images/overlay.png'), url('../assets/images/melantonic_tunnel_bw.jpg');
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, top center;
  background-repeat: repeat, no-repeat;

  &.style1 h2 {
    font-family: Ailerons, sans-serif;
    font-weight: 300;
    text-shadow: 0 4px 5px #00000024;

    @include breakpoint('>=medium') {
      font-size: 5em;
    }

    @include breakpoint('<medium') {
      font-size: 4.25em;
    }

    @include breakpoint('<small') {
      font-size: 3em;
    }
  }

  & footer {
    @include breakpoint('<=xsmall') {
      display: none;
    }
  }

  & .tickets {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2em;
    line-height: 1em;
    font-weight: 900;
    margin-top: 1em;
    background: rgba(0, 0, 0, 0.24);
    border-radius: 0.1em;
    padding: 0.4em;

    & img {
      width: 180px;
      height: auto;
      margin-right: 1em;
    }

    & .tickets__link {
      margin-left: 1em;
    }

    @include breakpoint('<=small') {
      font-size: 1.5em;

      & img {
        width: 100px;
      }
    }

    @include breakpoint('<=xsmall') {
      font-size: 1.1em;
    }
  }

  @include breakpoint('>=xlarge') {
    background-position: top left, top center;
  }

  @include breakpoint('<=small') {
    background-size: 256px 256px, 140%;
    background-position: top left, top center;
    height: 85vw !important;
    min-height: 270px;
  }

  @include breakpoint('<=xsmall') {
    background-size: 256px 256px, 150%;
  }
}

/*
		Latest video
	*/

#latest-video {
  background: url('../assets/images/overlay.png'), url('../assets/images/background/maschendrahtzaun.jpg');
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;
  display: flex;
  flex-direction: column;
  gap: 1em;

  > div {
    margin: auto;
    width: 90%;

    @include breakpoint('>small') {
      width: 80%;
    }
  }

  & iframe {
    margin: auto;
    width: 100%;
    height: 45vw;

    //@include breakpoint('>xsmall') {
    //	height: 300px;
    //}
    //
    @include breakpoint('>small') {
      height: 45vw;
    }
  }
}

/* One */

#music {
  background: url('../assets/images/overlay.png'), url('../assets/images/background/knust_side_sw_mlntnc.jpg');
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;

  & .icons {
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    text-align: center;

    & li a {
      font-size: 1.2em;
    }

    @include breakpoint('>small') {
      display: none;
    }
  }

  & img {
    width: 100%;
    height: auto;
  }

  //& iframe.youtube-iframe {
  //	width: 250px;
  //	height: 330px;
  //
  //	@include breakpoint('>small') {
  //		width: 100%;
  //		height: 80px;
  //	}
  //}

  & iframe.spotify-iframe {
    margin: auto;
  }
}

/* Two */

#video {
  background: url('../assets/images/overlay.png'), url('../assets/images/background/arthurs_table_dreh_mlntnc.jpg');
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;

  & iframe {
    //   width: 100%;
    //   height: 200px;

    //   @include breakpoint('>xsmall') {
    //     height: 300px;
    //   }

    //   @include breakpoint('>small') {
    //     height: 320px;
    //   }
  }
}

#drink {
  background: url('../assets/images/overlay.png'), url('../assets/images/background/Drink_MELANTONIC.jpg');
  background-size: 256px 256px, cover;
  background-attachment: fixed, fixed;
  background-position: top left, center center;

  & iframe {
    width: 100%;
    height: 200px;

    @include breakpoint('>xsmall') {
      height: 280px;
    }

    @include breakpoint('>small') {
      height: 260px;
    }
  }
}

/* Contact */

#contact {
  overflow: hidden;
  padding-bottom: 0;

  .box {
    @include vendor('transition', 'transform #{_duration(fade-in)} ease');
    @include vendor('transform', 'translateY(0)');
    position: relative;
    font-size: 1.2em;
  }

  &.inactive {
    .box {
      @include vendor('transform', 'translateY(100%)');
    }
  }

  @include breakpoint('<=small') {
    .box {
      padding: 1.5em 1.5em 2em 1.5em;
    }
  }

  @include breakpoint('<=xsmall') {
    .box {
      padding: 1em 1em 2em 1em;
    }
  }
}
